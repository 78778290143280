import { throttle } from 'lodash';
import {
  SETTINGS_EVENT_ID,
  UPDATE_FULL_POST_STYLE,
} from '@wix/communities-blog-client-common';
import { ABOVE_POST_LIST } from '../../../constants/ooi-slots';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import concurrentEditing from './concurrent-editing';
import { eventListenersSplitPostPage } from './event-listeners-blocks-post-page';
import { setFullpostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context: EditorAppContext) => {
  const { sdk } = context;
  const throttledSetParams = throttle(setFullpostStyleParams, 1000);

  await Promise.all([
    sdk.addEventListener('siteWasSaved', async () => {
      try {
        await savePostPageStyle(context);
      } catch (e) {}
    }),
    sdk.addEventListener('componentDataChanged', async (event) => {
      const eventType = (event?.detail?.previousData as any)?.content?.[
        SETTINGS_EVENT_ID
      ]?.payload;

      if (eventType === UPDATE_FULL_POST_STYLE) {
        await concurrentEditing.withApproval(async () => {
          throttledSetParams(context);
        });
      }
    }),
    eventListenersSplitPostPage(context),
    context.isClassicEditor
      ? sdk.addEventListener('widgetPluginAdded', (event) => {
          if (event.detail.placement.slotId === ABOVE_POST_LIST) {
            setTimeout(async () => {
              const children = await sdk.components.getChildren(
                context.appToken,
                {
                  componentRef: event.detail.hostComponentRef,
                },
              );

              await sdk.selection.selectComponentByCompRef(context.appToken, {
                compsToSelect: [children[0]],
              });
            }, 4000);
          }
        })
      : null,
  ]);
};
